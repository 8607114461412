import React from "react";
import Helmet from "react-helmet";

import Layout from "components/Layout";
import Container from "components/Container";

const AboutPage = () => {
  return (
    <Layout pageName="about">
      <Helmet>
        <title>About</title>
      </Helmet>
      <Container>
        <h1>Caim Skin &amp; Wellnes</h1>
        <p className="grey-text">Founded by Aoife O'Grady</p>

        <p>
          A Sanctuary for Holistic Therapies, Advanced Skin &amp; Beauty Treatments. 
          Caim is a stockist of Irish &amp; International brands, to bring the highest quality service to you. 
          Caim Skin &amp; Wellness is your Sanctuary for finding &amp; enhancing your best self, so that you can shine your light on the world!
        </p>

        <p>
          Aoife began her career in the beauty industry working and developing her skills as an advanced laser therapist. She has worked in the most renowned and award winning clinics in the country. Since delving deeper into advanced skin treatments and holistic therapies, Aoife hopes to give her very best to each of her clients by welcoming them to Caim.
        </p>

      </Container>
    </Layout>
  );
};

export default AboutPage;
